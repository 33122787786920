@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    height: 100vh;
    width: 100vw;
}
